import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { styled } from '@mui/material/styles';
import { SocialButton } from '../Social/Social';

const AuthorInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const AuthorImageContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
  marginRight: theme.spacing(4),
  '& img': {
    [theme.breakpoints.down('md')]: {
      height: '60px',
      width: '60px',
      borderRadius: '60px',
    },
    borderRadius: '0.5rem',
    overflow: 'hidden',
    height: '200px',
    width: '200px',
  },
}));

const AuthorName = styled('h1')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const AuthorBio = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
}));

const AuthorSocial = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(),
  '& .socialLink': {
    paddingRight: theme.spacing(4),
  },
  '& .socialImage': {
    height: 24,
    width: 24,
  },
}));

function linkWrapper(component, to, disableLink) {
  if (disableLink) {
    return component;
  }
  return (
        <Link to={to}>
            {component}
        </Link>
  );
}

export default function AuthorInfo({
  author, disableAuthorLinks,
}) {
  const {
    id, name, bio, image,
    twitter, linkedin, facebook, instagram, youtube,
  } = author;
  return (
    <AuthorInfoContainer>
        <AuthorImageContainer>
            {linkWrapper(
                <GatsbyImage image={image} />,
                `/authors/${id}/`,
                disableAuthorLinks,
            )}
        </AuthorImageContainer>
        <div>
            {linkWrapper(
                <>
                  <AuthorName>
                    {name}
                  </AuthorName>
                  <AuthorBio>
                    {bio}
                  </AuthorBio>
                </>,
                `/authors/${id}/`,
                disableAuthorLinks,
            )}
            <AuthorSocial>
            {
                twitter
                && <SocialButton
                    platform="twitter"
                    href={`https://twitter.com/${twitter}/`}
                    linkClassName="socialLink"
                    iconClassName="socialImage"
                />
            }
            {
                linkedin
                && <SocialButton
                    platform="linkedin"
                    href="https://www.linkedin.com/company/nualang/"
                    linkClassName="socialLink"
                    iconClassName="socialImage"
                />
            }
            {
                instagram
                && <SocialButton
                    platform="instagram"
                    href="https://instagram.com/nualangapp"
                    linkClassName="socialLink"
                    iconClassName="socialImage"
                />
            }
            {
                facebook
                && <SocialButton
                    platform="facebook"
                    href="https://www.facebook.com/nualangapp"
                    linkClassName="socialLink"
                    iconClassName="socialImage"
                />
            }
            {
                youtube
                && <SocialButton
                    platform="youtube"
                    href="https://www.youtube.com/channel/UCVApUy9Pp7bKp8nC74Znstg"
                    linkClassName="socialLink"
                    iconClassName="socialImage"
                />
            }
            </AuthorSocial>
        </div>
    </AuthorInfoContainer>
  );
}

AuthorInfo.propTypes = {
  author: PropTypes.object,
  disableAuthorLinks: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Divider from '@mui/material/Divider';
import { graphql } from 'gatsby';
import Layout from '../../layouts/Default';
import Section from '../../components/Section';
import AuthorInfo from '../../components/AuthorInfo/AuthorInfo';

const AuthorsPage = ({
  data: {
    allAuthorsYaml: { edges: authorNodes },
    allFile,
    site: {
      siteMetadata: { title },
    },
  },
}) => (
    <Layout>
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        })}
      >
        <Helmet title={`Authors | ${title}`} />
        <div>
          <div
            className="column is-12 is-offset-1"
          >
            <h1 className="title is-size-2 is-bold-light">Authors</h1>
          </div>
          {authorNodes.map(({ node: author }) => {
            const { id } = author;
            const authorImage = allFile.edges.find(
              (element) => element.node.childImageSharp.fluid.originalName.startsWith(id),
            );
            return (
              <div className="columns" key={`author-${id}`} style={{ marginBottom: '1rem' }}>
                <div
                  className="column is-10 is-offset-1"
                >
                  <AuthorInfo
                    author={{
                      ...author,
                      image: authorImage.node.childImageSharp.gatsbyImageData,
                    }}
                  />
                  <Divider
                    sx={(theme) => ({
                      [theme.breakpoints.down('md')]: {
                        marginTop: theme.spacing(2),
                      },
                      marginTop: theme.spacing(3),
                    })}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Section>
    </Layout>
);

AuthorsPage.propTypes = {
  data: PropTypes.shape({
    allAuthorsYaml: PropTypes.object,
    allFile: PropTypes.object,
    site: PropTypes.object,
  }),
};

export default AuthorsPage;

export const authorPageQuery = graphql`query AuthorsQuery {
  site {
    siteMetadata {
      title
    }
  }
  allAuthorsYaml {
    edges {
      node {
        id
        name
        image
        description
        bio
        twitter
      }
    }
  }
  allFile(
    filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "authors"}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
            width: 200
            quality: 100
            placeholder: BLURRED
            layout: CONSTRAINED
          )
          fluid {
            originalName
          }
        }
      }
    }
  }
}
`;
